import { createContext, useContext } from 'react';
import userAPI from '../services/userAPI';
import { translations } from '../components/common/utils';
import { defaultLanguage } from '../components/common/appSettings';
import { useFetch } from '../components/common/fetchData.hooks';

const UserDataContext = createContext();

export const UserDataProvider = ({ children }) => {
  const { data, isLoading, error, reloadData } = useFetch(userAPI.getUserData);

  const savedLocale = data && data[0]?.selectedLocale;
    // Add getting language from localStorage for "authentication session has expired" error message
    // when user is not loged in and no data about selected language.
    // Replace "defaultLanguage" by language from localStorage if exist.

  const selectedLanguage = !translations[savedLocale] ? defaultLanguage : savedLocale;

  const translate = (key) => translations[selectedLanguage][key];

  return (
    <UserDataContext.Provider value={{
        translate,
        isLoading,
        error,
        reloadData,
        data
      }}>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserDataContext = () => useContext(UserDataContext );
