const TermsEn = () => (
  <div className={"tab-content"}>
    <h3>Terms of Service</h3>
    <p>
These Domevent Terms of Service (the "Terms and Conditions") rule the relationship between SonoDome, registered under the number NIP 8522584607, having its registered address at Brzozowa 13, Domaslaw, Poland and any person registered in its own name or in the name and on behalf of a legal entity (the "User") wishing to use the services offered on the Domevent platform accessible at the address http://domevent.com (the "Platform").
    </p>
    <p>SonoDome and the User are hereinafter referred to individually as a "Party" and collectively as the "Parties".</p>
    <p>If the User is a legal entity allowing its employees, delegates, directors and/or subcontractors to access the Domevent (these persons are hereinafter indistinctly referred as a "User"). In such a case, the legal entity undertakes to bring these Terms and Conditions to their attention. It guarantees that these persons will comply with these Terms and Conditions and, in any case, further warranties Domevent for any breach by these Users of these Terms and Conditions.</p>
    <p>These Terms and Conditions define the conditions under which the User can use the Platform.</p>
    <p>Any registration on the Platform implies unreserved acceptance of the Terms and Conditions, which the User acknowledges having read and understood.</p>
    <h4>Article 1 – Definitions</h4>
    <p>Terms and expressions whose first letter of each word is in capital letters have, within the Terms and Conditions, the meaning assigned to them below, whether they are used in the singular or plural.</p>
    <p><b>1.0 « Force Majeure »</b> shall mean the events external to the Parties, unpredictable and unstoppable, as defined by Polish case law, the following events shall be deemed to constitute a case of Force Majeure (without purporting to be an exhaustive list): war (declared or not); terrorist act; invasion; rebellion; blockade; sabotage or act of vandalism; strike or social dispute, total or partial, external to both Parties; bad weather (in particular flooding, storms and hurricanes); events declared as "natural disasters"; zombie invasion; fire; epidemic; transport or supply blockades (particularly energy); failure of electrical energy, heating, air conditioning supplies, of the telecommunication or data transport network; satellite failure.</p>
    <p><b>1.1 « Intrusion Test »</b> shall mean any software tool (including in particular but not limited to Loadrunner, Winrunner, Silk performer, Rational Robot, QALoad and WebLOAD) designed to automatically emulate the actions of a human and used for data entry, data migration, load tests, performance tests, performance control, performance measuring and/or « stress tests ».</p>
    <h4>Article 2 – Subscription – Access to the Platform</h4>
    <p><b>2.1 Subscription</b></p>
    <p>The User represents and warrants that s/he will be using the Platform in the course of her/his professional activity and, as a consequence, s/he shall not be considered as a consumer under applicable law.</p>
    <p>In case of an incomplete or erroneous declaration the User's account may be deactivated, ipso jure, without notice and without further formality. The User acknowledges that SonoDome shall not be held liable for any of the consequences that may arise from such an erroneous or incomplete declaration.</p>
    <p>The User declares that s/he is able to enter into contractual relationships. Any User being under legal age warranties that s/he received the approval of her/his parents or of the person exercising the parental authority.</p>
    <p>The registration and the creation of a User account is done using the email address that the User communicated to Domevent.</p>
    <p>The email address is personal and should not be disclosed to third parties.</p>
    <p><b>2.2 Access to the Platform</b></p>
    <p>Users are personally responsible for setting up the IT and telecommunications resources required to access the Platform.</p>
    <p>SonoDome reserves the right, without notice or compensation, to temporarily or permanently close the Platform in order to perform maintenance operations.</p>
    <p>Domevent may make any changes and improvements to the Platform that it deems relevant.</p>
    <h4>Article 3 – Price conditions - Duration</h4>
    <p>Access to and use of the Domevent are offered by SonoDome at the prices featured on the payment section. The User takes out a subscription for the period that is defined at the time said subscription is taken out (the "Initial Period"). By accepting the Terms and Conditions, Users also accept the terms and conditions of use of the payment service provider. In case of failure to pay a subscription instalment, the User's access to the Platform will be immediately suspended.</p>
    <p><b>3.1 Users undertakings</b></p>
    <p>In the absence of the prior written authorisation of Domevent, it is prohibited to:</p>
    <ul>
      <li>(i)publish or to transmit, on or using the Platform, content of an illegal, threatening, humiliating, defamatory, obscene, hateful, child pornographic or blasphemous nature, or any other message that could constitute a crime or an offence, that could incur civil liability, breach legislation or incite others to do so, or content that could be used for any purposes that are contrary to law or to these Terms and Conditions;</li>
      <li>(ii)use the Platform in such a way as to, from our point of view, either negatively impact the performance or the functionalities of the Platform, or of any other computer system or network used by Domevent or by any other third party, or negatively affect Platform Users;</li>
      <li>(iii)download or transmit into the Platform or use any hardware, software or routines containing viruses, Trojan horses, worms, time bombs or other programs and processes designed to damage, interfere with or attempt to interfere with the normal operation of the Platform, or to appropriate the Platform, or to resort to any means whatsoever to saturate our systems or breach third party rights.</li>
    </ul>
    <h4>Article 4 – Personal data protection</h4>
    <p><b>4.1 Gathering of personal data by SonoDome</b></p>
    <p>SonoDome respects the privacy rights of the User and recognizes the importance of protecting the information collected about him/her. This Article 6 is about how Domevent collects, stores, and uses the personal information that the User provides to it.</p>
    <p>By approving this Terms of service, you consent to SonoDome handling of the personal data that you share with Domevent concerning yourself or third parties. Such consent is entirely voluntary and shall remain in force until you as User either withdraw your consent or close your account. 
    Domevent will, for its own use, process personal data related to the User, those data being (i) communicated by the User at the time of its registration or (ii) later on through her/his personal account on the Domevent.</p>
    <p>The information for which a communication to Domevent is mandatory are identified as such upon registration and/or the purchase of a service.</p>
    <p>Unless upon request or with the express consent of the User and in strict compliance with his/her directives, Domevent will not proceed with any other personal data processing other than those described in this Article 6.</p>
    <p><b>4.1.1 Legal basis for processing</b></p>
    <p>By registering, subscribing or using Domevent services, the User consents to the use of his/her personal data in line with these Terms and Conditions.</p>
    <p>Furthermore, in order to use the Domevent and benefit from SonoDome's services, the User accepted the terms and conditions.</p>
    <p>This document formalizes a contractual relationship between the User and SonoDome, which serves in particular as the legal basis for the collection and processing of the User's personal data by Domevent.</p>
    <p><b>4.1.2 Purposes of the processing</b></p>
    <p>Personal data gathered by TimeLine are processed for the following purposes:</p>
    <ul>
      <li>(i)Access and use of the Platform, understood as the consultation of the accessible information of the Platform;</li>
      <li>(ii)Sale of services;</li>
      <li>(iii)Order management (e.g., payment, billing);</li>
      <li>(iv)Statistical and market analysis, and marketing information;</li>
      <li>(v)Where required by law or in connection with legal proceeding or disputes;</li>
      <li>(vi)Any other use allowing the improvement of the Platform.</li>
    </ul>
    <p><b>4.1.3 Your rights</b></p>
    <p>The User has the right to consult, rectify, delete, object and portability their personal data. He/she also has the right to define guidelines on the fate of personal data after his/her death.</p>
    <p>In addition, the User may oppose, limit or withdraw her/his consent.</p>
    <p>To exercise any of those rights, the User shall contact SonoDome on mail@sonodome.com, mentioning her/his name, surname and e-mail address.</p>
    <p>You have the right at any time and at no cost, once a year, to request an extract of the file of your personal data that Domevent has saved concerning you. Should your data be incorrect, incomplete or irrelevant, you may request to have the information corrected or deleted. Domevent may not delete your data when a statutory requirement exists for storage, such as accounting rules or standards, or when other legitimate reasons exist as to why the data must be saved, such as unpaid liabilities. If you have provided information about a third party, such third party shall have the same rights. You may contact Domevent either by sending email to  mail@sonodome.com. </p>
    <p><b>4.1.4 Recipients of personal data</b></p>
    <p>The data that you give to Domevent is stored within the European Economic Area (EEA), but may also be transmitted to and processed in a country outside the EEA. All such transmission of your personal data shall take place in accordance with prevailing legislation.</p>
    <p>All personal information that the User provides to Domevent is strictly confidential.</p>
    <p><b>4.1.5 HIPAA privacy notice</b></p>
    <p>The User acknowledges in particular that Platform storage is not HIPAA compliant.</p>
    <p><b>4.1.6 Cookies</b></p>
    <p>This Platform uses cookies and localStorage so that Users do not need to enter their email or usernames or passwords at each visit and in order to facilitate exchanges. Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version.</p>
    <p>Blocking all cookies will have a negative impact upon the usability of many websites.
If you block cookies, you will not be able to use all the features on our platform.
    </p>
    <p><b>4.1.7 Statistical use of anonymous data</b></p>
    <p>For business purposes Domevent collects, handles and stocks statistical data on Platform use.</p>
    <p>This information may be transmitted to third parties, is anonymous and does not reveal in any way whatsoever your identity, even indirectly.</p>
    <h4>Article 5 – Intellectual property</h4>
    <p><b>5.1 Intellectual property rights related to the Platform</b></p>
    <p>The Platform is the exclusive property of SonoDome, as well as all associated documentation.</p>
    <p>All content that has not been put online by the User, and in particular, all data, functionality, text, names, designations, images, photographs, graphics, trademarks, sounds, is the exclusive property of SonoDome or its licensees.</p>
    <h4>Article 6 – Warranty – Liability</h4>
    <p>The Parties expressly agree that Domevent has no general obligation to monitor the contents that is made available and/or broadcasted from its Platform.</p>
    <p>To the full extent permitted by applicable law, Domevent expressly waives all warranties towards the Users. Domevent does not guarantee that there will be no bugs and, as a consequence, does not guarantee that use of the Platform will be uninterrupted and error-free. The User acknowledges in particular that Internet transmissions are not secured and may be delayed, lost, intercepted, corrupted, and that confidential information is transmitted over the Internet by the User at his/her own peril.</p>
    <p>To the full extent permitted by applicable law, the User acknowledges that the Platform is provided "as is"and as such SonoDome shall not be liable for any damage, direct or indirect, whatever the grounds, which may have been suffered by fact of using the Platform, and therefore waives any claim for compensation. If SonoDome should be ordered to pay a financial penalty for any reason whatsoever, the Parties expressly agree that the amount of said penalty shall not exceed the amount of the sums actually paid by the User during the last six (6) months of subscription, which the Parties agree would be a fair amount in view of their respective undertakings in these Terms and Conditions.</p>
    <p>Within the limits permitted by applicable statute, the User may only hold SonoDome liable for breach of these Terms and Conditions for a period of one (1) year from the date at which the breach in question arose, which the User specifically acknowledges and accepts.</p>
    <h4>Article 7 – Termination</h4>
    <p>The User may terminate his/her subscription by sending an e-mail to mail@sonodome.com. The termination of the Subscription shall be effective upon expiry of the ongoing Subscription period.
Would s/he wish so, the User may request that Domevent deletes its account.</p>
    <p>SonoDome reserves the right to (i) suspend access to the Platform or to certain functionalities of the Platform, or to (ii) terminate the account of the User by right, without notice, with no further formalities and no indemnity being due, by electronic mail, in the following cases:</p>
    <ul>
      <li>(i)The User has been using the Platform in a way which is contrary to public order and decency;</li>
      <li>(ii)The User has breached the intellectual property rights of SonoDome and/or a third party;</li>
      <li>(iii)Conception, use or provision via the Platform of any content that is unlawful or in breach of these Terms and Conditions;</li>
      <li>(iv)If an account is terminated the User may no longer use the Platform from his/her account, which will be closed.</li>
    </ul>
    <p>SonoDome reserves the possibility to interrupt, at any time, temporarily or permanently, access to the Platform. In case of a definitive interruption, the User will be informed by any means that should be deemed appropriate by SonoDome.</p>
    <p>SonoDome shall under no circumstances be held liable to the User, a Developer or to any third party for the termination and/or suspension of the User's account under the conditions set out in this article.</p>
    <h4>Article 8 – Miscellaneous</h4>
    <p><b>8.1 Modification of these Terms and Conditions</b></p>
    <p>SonoDome reserves the right, at any time, to modify these Terms and Conditions of use and sale as it may deem to be necessary and useful.</p>
    <p><b>8.2 Force Majeure</b></p>
    <p>Neither Party shall be held liable if the performance of its obligations should be delayed, restricted or made impossible by virtue of a case of force majeure</p>
    <p>If a case of Force Majeure should occur, the performance of the obligations of each Party shall be suspended. If the Force Majeure should last for more than one (1) month this Agreement may be terminated at the request of the most diligent of the Parties, with neither Party being held liable. Each of the Parties shall bear their own costs that may be incurred as a result of the Force Majeure.</p>
    <p><b>8.3 Waiver</b></p>
    <p>If one or other Party fails to exercise any one of its rights under the terms of this Agreement whatsoever, this shall not be deemed to be a waiver of that right, such waiver only to be deemed to have been given when expressly declared by the Party in question.</p>
    <p><b>8.4 Relationship between the Parties</b></p>
    <p>Unless expressly stated otherwise, the Parties shall remain independent contractors and no provision of these Terms and Conditions shall be construed as creating between the Parties any partnership, company, nor any relationship of agent or commercial agent.</p>
    <h4>Article 9 – Applicable law – Competent jurisdiction</h4>
    <p>These Terms and Conditions are governed by Polish law.
Any dispute related to the conclusion, the interpretation, the enforcement and/or the termination of these Terms and Conditions is submitted to the exclusive competence of the Polish Courts, notwithstanding plurality of defendants.
    </p>
  </div>
);

export default TermsEn;