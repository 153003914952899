import { useState } from "react";
import PropTypes from 'prop-types';
import { MdDeleteForever } from "react-icons/md";
import { logOutUrl } from '../../common/utils';
import { fetchData } from '../../common/commonRequests';
import coworkerAPI from '../../../services/coworkerAPI';
import ModalComponent from '../../common/ModalComponent';

const logOutCallback = () => (window.location.href = logOutUrl);

const CoworkerAcceptedRequest = ({
  translate,
  workSpaceEmail,
  workSpaceId,
  setIsLoading,
  setError
}) => {
  const [isModal, setIsModal] = useState(false);

  const handleRemove = () => {
    fetchData(
      setIsLoading,
      setError,
      coworkerAPI.removeCoworker,
      workSpaceId,
      logOutCallback
    );
  };

  return (
    <div className="workspaceWram">
      <ModalComponent 
        ModalContent={translate('areYouSureToRemoveSharedWorkspaceAccess')}
        onSubmitButtonHandler={handleRemove}
        onSubmitButtonName={translate('yesRemove')}
        show={isModal}
        handleClose={() => setIsModal(false)}
        type="WARNING"
      />
      <p>{workSpaceEmail}</p>
      <MdDeleteForever
        className="controls-icons"
        size={'1.5em'}
        onClick={() => setIsModal(true)}
        title={translate('remove')}
      />
    </div>
  );
};

CoworkerAcceptedRequest.propTypes = {
  translate: PropTypes.func.isRequired,
  workSpaceEmail: PropTypes.string.isRequired,
  workSpaceId: PropTypes.string.isRequired,
  reloadData: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
};

export default CoworkerAcceptedRequest;
