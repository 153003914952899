import PropTypes from 'prop-types';
import moment from 'moment';
import { useUserDataContext } from '../../contexts/userDataProvider';
import { Table } from "react-materialize";
import { dateFormat } from '../common/appSettings';

const CartContent = ({
  orderData = {
    totalCost: '--',
    surname: '',
    email: '',
    feeding: false,
    description: '',
  },
  costSum = '--',
  isDisplayFeedingOption = false
}) => {
  const {
    firstName,
    surname,
    email,
    phone,
    description,
    feeding,
    createdAt,
    costPerNight,
    totalCost
  } = orderData;

  const { translate } = useUserDataContext();
  
// Minor issue: "totalCost || costSum" does not update if cart is visible and dates are changed in Timeline
  return (
    <Table>
      <tbody>
        <tr>
          <td>{translate('name')}:</td>
          <td>{firstName} {surname}</td>
        </tr>
        <tr>
          <td>{translate('email')}:</td>
          <td>{email}</td>
        </tr>
        <tr>
          <td>{translate('phone')}:</td>
          <td>{phone}</td>
        </tr>
        {
          isDisplayFeedingOption? (
            <tr>
              <td>{translate('feeding')}:</td>
              <td>{feeding ? translate('yes') : translate('no')}</td>
            </tr>
          ) : null
        }
        <tr>
          <td>{translate('created')}:</td>
          <td>{moment(createdAt).format(dateFormat)}</td>
        </tr>
        <tr>
          <td>{translate('cost')}:</td>
          <td>{costPerNight} / {totalCost || costSum} {translate('costNightTotal')}</td>
        </tr>
        <tr>
          <td>{translate('comments')}:</td>
          <td>{description}</td>
        </tr>
      </tbody>
    </Table>
  )
};

CartContent.propTypes = {
  orderData: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    surname: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string.isRequired,
    description: PropTypes.string,
    feeding: PropTypes.bool,
    createdAt: PropTypes.string.isRequired,
    costPerNight: PropTypes.number.isRequired,
    totalCost: PropTypes.number
  }).isRequired,
  isDisplayFeedingOption: PropTypes.bool,
  costSum: PropTypes.number
};

export default CartContent;
