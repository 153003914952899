import PropTypes from 'prop-types';
import { Switch } from "react-materialize";

const SetFeeding = ({ handleSaveEditData, isDisplayFeedingOption = false, translate }) => {

  const handleFeeding = (isFeeding) => {
    handleSaveEditData({ isDisplayFeedingOption: isFeeding })
  };

  return (
    <div className="settings-set-feeding-wrap">
      <span className="switchLabel">{translate('displayFeedingOption')}</span>
      <Switch
        label={translate('displayFeedingOption')}
        id="settings-is-feeding-option"
        offLabel={translate('no')}
        onLabel={translate('yes')}
        checked={isDisplayFeedingOption}
        onChange = {(e) => handleFeeding(e.target.checked )}
      />
    </div>
  );
};

SetFeeding.propTypes = {
  handleSaveEditData: PropTypes.func.isRequired,
  isDisplayFeedingOption: PropTypes.bool,
  translate: PropTypes.func.isRequired
};

export default SetFeeding;
