const TermsEn = () => (
  <div className={"tab-content"}>
    <h3>Умови надання послуг</h3>
    <p>
Ці Domevent Умови надання послуг (далі - "Умови") регулюють відносини між SonoDome, зареєстрованою під номером NIP 8522584607, за адресою Brzozowa 13, Domaslaw, Poland, та будь-якою особою, яка зареєстрована на своє ім'я або в ім'я та від імені юридичної особи (далі - "Користувач"), яка бажає використовувати послуги, пропоновані на платформі Domevent, доступній за адресою http://domevent.com (далі - "Платформа").
    </p>
    <p>SonoDome та Користувач далі індивідуально називаються "Стороною" і колективно - "Сторонами".</p>
    <p>Якщо Користувач є юридичною особою, яка надає своїм працівникам, делегатам, директорам та/або підрядникам доступ до Domevent (ці особи далі називаються "Користувач"). У такому випадку юридична особа зобов'язується звернути їхню увагу до цих Умов. Вона гарантує, що ці особи будуть дотримуватися цих Умов і забезпечує Domevent від будь-якого порушення цими Користувачами цих Умов.</p>
    <p>Ці Умови визначають умови, за яких Користувач може використовувати Платформу.</p>
    <p>Будь-яка реєстрація на Платформі передбачає беззастережне прийняття Умов, які Користувач визнає, що він прочитав і зрозумів.</p>
    <h4>Стаття 1 - Визначення</h4>
    <p>Терміни та вирази, перша літера кожного слова яких написана великою літерою, мають, згідно з Умовами, значення, надане нижче, незалежно від того, чи використовуються вони в однині чи множині.</p>
    <p><b>1.0 «Форс-мажор»</b> означає події, зовнішні для Сторін, непередбачувані та невпинні, як визначено польським судовим рішенням, наступні події вважаються випадком форс-мажору (не вичерпний список): війна (оголошена або ні); терористичний акт; вторгнення; повстання; блокада; саботаж чи акт вандалізму; страйк чи соціальний конфлікт, повний або частковий, зовнішній для обох Сторін; погана погода (зокрема повені, шторми та урагани); події, оголошені "природними катастрофами"; вторгнення зомбі; пожежа; епідемія; блокади транспорту чи постачання (зокрема енергії); відмова в електроенергії, постачанні тепла, кондиціонуванні повітря, транспортній або телекомунікаційній мережі; відмова супутника.</p>
    <p><b>1.1 «Тест на вторгнення»</b> означає будь-який програмний інструмент (зокрема, але не виключно, Loadrunner, Winrunner, Silk performer, Rational Robot, QALoad та WebLOAD), призначений для автоматичного емулювання дій людини та використовуваний для введення даних, міграції даних, навантажувальних тестів, тестів продуктивності, контролю продуктивності, вимірювання продуктивності та/або «стрес-тестів».</p>
    <h4>Стаття 2 - Підписка - Доступ до Платформи</h4>
    <p><b>2.1 Підписка</b></p>
    <p>Користувач заявляє та гарантує, що він буде використовувати Платформу в межах своєї професійної діяльності і, відповідно, не буде вважатися споживачем відповідно до чинного законодавства.</p>
    <p>У випадку неповної чи невірної декларації облікового запису Користувача може бути деактивований, ipso jure, без попередження та без подальших формальностей. Користувач визнає, що SonoDome не несе відповідальності за будь-які наслідки, що можуть виникнути в результаті такої невірної чи неповної декларації.</p>
    <p>Користувач заявляє, що він може укладати договірні відносини. Будь-який Користувач, який не досяг правового віку, гарантує, що він отримав згоду своїх батьків або особи, яка здійснює батьківську владу.</p>
    <p>Реєстрація та створення облікового запису Користувача виконується за допомогою електронної адреси, яку Користувач повідомив Domevent.</p>
    <p>Електронна адреса є особистою і не повинна розголошуватися третім особам.</p>
    <p><b>2.2 Доступ до Платформи</b></p>
    <p>Користувачі особисто відповідають за налаштування ІТ та телекомунікаційних ресурсів, необхідних для доступу до Платформи.</p>
    <p>SonoDome залишає за собою право без повідомлення чи компенсації тимчасово чи постійно закривати Платформу для проведення технічних робіт.</p>
    <p>SonoDome може вносити будь-які зміни та покращення до Платформи, які вважає за потрібні.</p>
    <h4>Стаття 3 - Умови цін - Тривалість</h4>
    <p>Доступ та використання Domevent надаються SonoDome за цінами, вказаними в розділі оплати. Користувач оформляє підписку на період, визначений на час оформлення такої підписки ("Початковий період"). При прийнятті Умов користувачі також приймають умови використання постачальника послуг з оплати. У разі невнесення оплати за підписку доступ Користувача до Платформи буде негайно призупинено.</p>
    <p><b>3.1 Зобов'язання Користувачів</b></p>
    <p>Без попереднього письмового дозволу Domevent заборонено:</p>
    <ul>
      <li>(i) публікувати або передавати чи використовувати Платформу контент незаконного, загрозливого, упокійливого, дефамаційного, непристойного, ненависного, дитячо-порнографічного або богохульного характеру, або будь-яке інше повідомлення, яке може становити злочин чи правопорушення, що може призвести до цивільної відповідальності, порушити законодавство або підштовхнути інших до цього, або контент, який може використовуватися для будь-яких цілей, що суперечать закону чи цим Умовам;</li>
      <li>(ii) використовувати Платформу таким чином, який, на наш погляд, може негативно вплинути на продуктивність або функціональність Платформи, або будь-якої іншої комп'ютерної системи чи мережі, використовуваної SonoDome чи будь-яким іншим третім суб'єктом, або негативно впливати на Користувачів Платформи;</li>
      <li>(iii) завантажувати чи передавати на Платформу чи використовувати апаратне чи програмне забезпечення, що містить віруси, троянські коні, черви, часові бомби або інші програми та процеси, призначені для пошкодження, заваджання або спроби втручання в нормальну роботу Платформи, чи захоплення контролю Платформи.</li>
    </ul>
    <h4>Стаття 4 - Захист особистих даних</h4>
    <p><b>4.1 Збір особистих даних SonoDome</b></p>
    <p>SonoDome поважає права Користувача на конфіденційність та визнає важливість захисту інформації, зібраної про нього. У цій Статті йдеться про те, як Domevent збирає, зберігає та використовує особисту інформацію, яку Користувач надає.</p>
    <p>Погоджуючись на ці Умови надання послуг, ви надаєте згоду на обробку особистих даних, які ви надаєте Domevent стосовно себе чи третіх осіб. Така згода є цілком добровільною та залишається чинною, доки ви, як Користувач, не відкликаєте свою згоду або не закриєте свій обліковий запис.
    Domevent буде обробляти особисті дані, пов'язані з Користувачем, для власного використання, ці дані будуть (i) надані Користувачем під час реєстрації або (ii) пізніше через його особистий обліковий запис на Domevent.</p>
    <p>Інформація, для якої обов'язковою є комунікація Domevent, визначена як така при реєстрації та/або при покупці послуги.</p>
    <p>За відсутності запитань або з явною згодою Користувача та в строгому відповідності до його/її вказівок, Domevent не буде здійснювати обробку будь-яких інших особистих даних, крім тих, які описані в цій Статті.</p>
    <p><b>4.1.1 Правова підстава для обробки</b></p>
    <p>Реєструючись, підписуючись чи використовуючи послуги Domevent, Користувач згоден на використання своїх особистих даних відповідно до цих Умов.</p>
    <p>Крім того, для використання Domevent та отримання послуг від SonoDome, Користувач прийняв Умови.</p>
    <p>Цей документ формалізує контрактові відносини між Користувачем і SonoDome, який служить, зокрема, правовою підставою для збору та обробки особистих даних Користувача Domevent.</p>
    <p><b>4.1.2 Цілі обробки</b></p>
    <p>Особисті дані, які збираються Domevent, обробляються з такими цілями:</p>
    <ul>
      <li>(i) Доступ та використання Платформи;</li>
      <li>(ii) Продаж послуг;</li>
      <li>(iii) Управління замовленнями (наприклад, оплата, виставлення рахунків);</li>
      <li>(iv) Статистичний та ринковий аналіз, та маркетингова інформація;</li>
      <li>(v) Де це вимагається законом або в зв'язку з юридичними процедурами чи суперечками;</li>
      <li>(vi) Будь-яке інше використання, що дозволяє поліпшити Платформу.</li>
    </ul>
    <p><b>4.1.3 Ваші права</b></p>
    <p>Користувач має право консультуватися, виправляти, видаляти, висловлювати своє заперечення та переносити свої особисті дані. Він/вона також має право визначити вказівки щодо долі своїх особистих даних після своєї смерті.</p>
    <p>Крім того, Користувач може висловити своє заперечення, обмежити або відкликати свою згоду.</p>
    <p>Для використання будь-якого з цих прав, Користувач повинен звертатися до SonoDome за адресою mail@sonodome.com, зазначаючи своє ім'я, прізвище та електронну адресу.</p>
    <p>Ви маєте право в будь-який час і безкоштовно, один раз на рік, запитати витяг з файлу особистих даних, який Domevent зберігає щодо вас. Якщо ваші дані є невірними, неповними або неактуальними, ви можете вимагати виправлення або видалення інформації. Domevent не може видаляти ваші дані, якщо існує закономірна вимога збереження, така як бухгалтерські правила чи стандарти, або коли існують інші законні причини для збереження даних, такі як несплачені зобов'язання. Якщо ви надали інформацію щодо третьої сторони, така третя сторона матиме ті ж права. Ви можете звертатися до SonoDome, надсилаючи електронний лист на адресу mail@sonodome.com.</p>
    <p><b>4.1.4 Одержувачі особистих даних</b></p>
    <p>Дані, які ви надаєте Domevent, зберігаються в межах Європейської економічної зони (ЄЄЗ), але можуть також передаватися та оброблятися в країні поза ЄЄЗ. Всі такі передачі ваших особистих даних відбуватимуться відповідно до чинного законодавства.</p>
    <p>Всі особисті дані, які Користувач надає Domevent, є строго конфіденційними.</p>
    <p><b>4.1.5 Повідомлення про конфіденційність HIPAA</b></p>
    <p>Користувач визнає, зокрема, що зберігання на Платформі не відповідає вимогам HIPAA.</p>
    <p><b>4.1.6 Cookies</b></p>
    <p>Ця Платформа використовує файли cookies та localStorage, щоб Користувачам не потрібно було вводити свою електронну адресу, імена користувачів чи паролі кожного разу при візиті. Більшість браузерів дозволяють вам відмовитися від приймання файлів cookies та видаляти їх. Методи для цього варіюються від браузера до браузера та від версії до версії.</p>
    <p>Блокування всіх файлів cookies матиме негативний вплив на можливості використання багатьох веб-сайтів.
Якщо ви блокуєте файли cookies, ви не зможете використовувати всі функції нашої платформи.
    </p>
    <p><b>4.1.7 Статистичне використання анонімних даних</b></p>
    <p>З метою бізнесу Domevent збирає, обробляє та зберігає статистичні дані використання Платформи.</p>
    <p>Ця інформація може передаватися третім сторонам, вона є анонімною і в жоден спосіб не розкриває вашої особи, навіть опосередковано.</p>
    <h4>Стаття 5 - Інтелектуальна власність</h4>
    <p><b>5.1 Права інтелектуальної власності, пов'язані з Платформою</b></p>
    <p>Платформа є виключною власністю SonoDome, а також всієї пов'язаної документації.</p>
    <p>Весь вміст, який не був розміщений у вільному доступі в Інтернеті, зокрема, всі дані, функціональність, текст, імена, позначення, зображення, фотографії, графіка, товарні знаки, звуки, є виключною власністю SonoDome чи її ліцензіатів.</p>
    <h4>Стаття 6 - Гарантія - Відповідальність</h4>
    <p>Сторони чітко узгоджують, що SonoDome не має загального обов'язку контролювати вміст, який стає доступним та/або транслюється з його Платформи.</p>
    <p>На повний обсяг, який дозволяє чинне законодавство, SonoDome відмовляється від усіх гарантій перед Користувачами. SonoDome не гарантує, що не буде помилок, і, отже, не гарантує, що використання Платформи буде безперервним та без помилок. Користувач визнає зокрема, що передачі в Інтернеті не є захищеними і можуть бути затримані, втрачені, перехоплені, пошкоджені, і що конфіденційна інформація передається через Інтернет на його/її власний ризик.</p>
    <p>На повний обсяг, який дозволяє чинне законодавство, Користувач визнає, що Платформа надається "як є", і в такий спосіб SonoDome не несе відповідальності за будь-які збитки, безпосередні чи опосередковані, незалежно від підстав, які можуть виникнути внаслідок використання Платформи, і тому відмовляється від будь-яких претензій на компенсацію. Якщо SonoDome буде зобов'язана сплатити фінансову пеню з будь-якої причини, Сторони зазначають, що сума такої пені не перевищить суми, фактично сплачені Користувачем протягом останніх шести (6) місяців передплати, яку Сторони вважають справедливою сумою, враховуючи їхні взаємні зобов'язання згідно з цими Умовами.</p>
    <p>На межах, які дозволяє чинне законодавство, Користувач може вимагати відшкодування від SonoDome за порушення цих Умов протягом одного (1) року з дати виникнення порушення, що Користувач визнає і приймає.</p>
    <h4>Стаття 7 - Розірвання</h4>
    <p>Користувач може припинити свою підписку, надсилайте електронний лист на адресу mail@sonodome.com. Розірвання підписки набуває чинності з закінченням поточного періоду підписки.
    За бажанням, Користувач може попросити Domevent видалити його обліковий запис.</p>
    <p>SonoDome залишає за собою право (i) призупинити доступ до Платформи або до певних функцій Платформи, або (ii) eceyenb обліковий запис Користувача без повідомлення, без додаткових формальностей та без виплати відшкодування, електронною поштою, у наступних випадках:</p>
    <ul>
        <li>(i) Користувач використовував Платформу способом, що суперечить громадському порядку та моралі;</li>
        <li>(ii) Користувач порушив права інтелектуальної власності SonoDome та/або третьої сторони;</li>
        <li>(iii) Створення, використання або надання через Платформу будь-якого контенту, який є неправомірним або порушує ці Умови;</li>
        <li>(iv) Якщо умову розірвано, Користувач більше не може використовувати Платформу зі свого облікового запису, який буде закритий.</li>
    </ul>
    <p>SonoDome залишає за собою можливість припинити, в будь-який момент, тимчасово або постійно, доступ до Платформи. У разі остаточного припинення, Користувач буде інформований будь-якими засобами, які SonoDome вважатиме за відповідні.</p>
    <p>SonoDome в жодному разі не несе відповідальності перед Користувачем, Розробником чи будь-якою третьою стороною за розірвання та/або призупинення облікового запису Користувача в умовах, встановлених у цій статті.</p>
    <h4>Стаття 8 - Різне</h4>
    <p><b>8.1 Зміна цих Умов</b></p>
    <p>SonoDome залишає за собою право в будь-який момент змінювати ці Умови використання.</p>
    <p><b>8.2 Форс-мажор</b></p>
    <p>Жодна із Сторін не несе відповідальності, якщо виконання її зобов'язань буде затримано, обмежено або неможливо здійснити у зв'язку з випадком форс-мажору.</p>
    <p>У разі виникнення форс-мажорних обставин виконання зобов'язань кожної Сторони призупиняється. Якщо форс-мажор триватиме більше одного (1) місяця, цю Угоду можна розірвати за запитом найбільш старанної із Сторін, без відповідальності будь-якої із Сторін. Кожна з Сторін несе свої власні витрати, які можуть виникнути в результаті форс-мажорних обставин.</p>
    <p><b>8.3 Відмова</b></p>
    <p>Якщо одна зі Сторін не скористалася будь-яким зі своїх прав відповідно до Умови, це не буде вважатися відмовою від цього права, така відмова вважатиметься наданою лише тоді, коли це буде явно заявлено Стороною, що робить відмову.</p>
    <p><b>8.4 Відносини між Сторонами</b></p>
    <p>Крім випадків явного зазначення іншого, Сторони залишаються незалежними контрагентами, і жодне положення цих Умов не тлумачиться як створення між Сторонами будь-якого партнерства, товариства чи будь-яких відносин агента чи комерційного агента.</p>
    <h4>Стаття 9 - Застосовний закон - Компетентна юрисдикція</h4>
    <p>Ці Умови підпорядковані польському законодавству.
Будь-який спір, пов'язаний із укладенням, тлумаченням, виконанням і/або розірванням цих Умов, підлягає виключній компетенції польських судів, незважаючи на наявність кількох відповідачів.
    </p>
  </div>
);

export default TermsEn;