import PropTypes from 'prop-types';
import { useUserDataContext } from '../../contexts/userDataProvider';
import { Switch, TextInput, Textarea } from "react-materialize";
import { getRequiredInputParams } from '../common/utils';

const CartContentEdit = ({
    editOrderData,
    setEditOrderData,
    costSum = '--',
    isDisplayFeedingOption
}) => {
  const { translate } = useUserDataContext();

  const handleDataSet = (data) => {
    const key = Object.keys(data)[0];

    setEditOrderData(prevState => ({
      ...prevState,
      [key]: data[key]
    }));
  };

  return (
    <div className="cart-content-edit">
      <div className="cart-row">
        <p>{translate('name')}: *</p>
        <TextInput
          value={editOrderData.editFirstName}
          onChange = {(e) => handleDataSet({ editFirstName: e.target.value })}
          id="firstName"
          maxLength="30"
          {...getRequiredInputParams(translate('canNotBeEmpty'))}
        />
      </div>
      <div className="cart-row">
        <p>{translate('surname')}:</p>
        <TextInput
          value={editOrderData.editSurname}
          onChange = {(e) => handleDataSet({ editSurname: e.target.value })}
          id="surname"
          maxLength="30"
        />
      </div>
      <div className="cart-row">
        <p>{translate('email')}:</p>
        <TextInput
          email
          validate
          value={editOrderData.editEmail}
          onChange = {(e) => handleDataSet({ editEmail: e.target.value })}
          id="email"
          maxLength="30"
        />
      </div>
      <div className="cart-row">
        <p>{translate('phone')}: *</p>
        <TextInput
          value={editOrderData.editPhone}
          id="phone"
          onChange = {(e) => handleDataSet({ editPhone: e.target.value })}
          maxLength="16"
          {...getRequiredInputParams(translate('canNotBeEmpty'))}
        />
      </div>
      {
        isDisplayFeedingOption ? (
          <div className="cart-row">
            <p>{translate('feeding')}:</p>
            <Switch
              id={`feeding-edit-order${editOrderData.id}`}
              offLabel={translate('no')}
              onLabel={translate('yes')}
              checked={editOrderData.editFeeding}
              onChange = {(e) => handleDataSet({ editFeeding: e.target.checked })}
            />
          </div>
        ) : null
      }
      <div className="cart-row">
        <p>{translate('totalCost')}:</p>
        <TextInput
          value={editOrderData.totalCost || costSum}
          onChange = {(e) => handleDataSet({ totalCost: Number(e.target.value) })}
          id="totalCost"
          maxLength="8"
        />
      </div>
      <div className="cart-row">
        <p>{translate('comments')}:</p>
        <Textarea
          value={editOrderData.editDescription}
          id="description"
          onChange = {(e) => handleDataSet({ editDescription: e.target.value })}
          maxLength="200"
        />
      </div>
    </div>
  )
};

CartContentEdit.propTypes = {
  editOrderData: PropTypes.shape({
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    editDescription: PropTypes.string,
    editEmail: PropTypes.string,
    editFeeding: PropTypes.bool,
    editFirstName: PropTypes.string,
    editPhone: PropTypes.string,
    editSurname: PropTypes.string,
    totalCost: PropTypes.number
  }).isRequired,
  setEditOrderData: PropTypes.func.isRequired,
  isDisplayFeedingOption: PropTypes.bool.isRequired,
  costSum: PropTypes.number // Some time number sometime string. Should be number
};

export default CartContentEdit;
