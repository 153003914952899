import { usePropertyContext } from '../../../contexts/propertyProvider';
import { useUserDataContext } from '../../../contexts/userDataProvider';
import AddNewProperty from './AddNewProperty';
import PropertyItemComponent from "./PropertyItemComponent";
import Loader from '../../common/Loader';
import ErrorAlert from '../../common/ErrorAlert';

const AllProperties = () => {
  const { data, isLoading, error, reloadData } = usePropertyContext();
  const { translate } = useUserDataContext();

  return (
    <>
      <h5>{translate('manageProperties')}</h5>
      <div className={'all-properties'}>
        <Loader 
          setIsLoading={isLoading}
        />
        <ErrorAlert
          error={error}
        />
        {data?.map(({ id, title, costPerNight, description, isAvailable }) => (
          <PropertyItemComponent
            key={id}
            id={id}
            title={title}
            costPerNight={costPerNight}
            description={description}
            isAvailable={isAvailable}
            reloadPropertiesList={reloadData}
          />
        ))}
        <div className="add-new-property-wrap">
          {data?.length === 0 && <div className="nothing-found">{translate('addProperty')}</div>}

          <AddNewProperty
            reloadPropertiesList={reloadData}
            allProperties={data}
          />
        </div>
      </div>   
    </>
  );
};

export default AllProperties;
