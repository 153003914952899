import { useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import { IoCloseOutline } from "react-icons/io5";
import { useUserDataContext } from '../../contexts/userDataProvider';
import { Button } from "react-materialize";

const ModalComponent = ({
  handleClose,
  show,
  ModalContent,
  onSubmitButtonHandler,
  onSubmitButtonName,
  type,
  secondaryButtonHandler,
  secondaryButtonName,
  hideCancelButton
}) => {
  const { translate } = useUserDataContext();

  const targetRef = useRef(null);

  const scrollToTarget = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getModalContet = () => {
    if (typeof ModalContent === 'function') {
      return <ModalContent />
    }

    if (typeof ModalContent === 'object') {
      return ModalContent
    } else {
      return <p>{ ModalContent }</p>
    }
  };

  useEffect(() => {
    if (show) {
      scrollToTarget();
    }
  }, [show]);

  return (
    <div className={show ? "modal-wrap show-modal" : "modal hide-modal"} ref={targetRef}>
      <div className={`modal-main ${type ==='WARNING' ? 'warning' : 'common'}`}>
        <IoCloseOutline
          className="modal-close-btn"
          onClick={handleClose}
        />
        {getModalContet()}
          <div className="button-wrap">
            {onSubmitButtonHandler && (
              <Button
                node="button"
                waves="light"
                style={{
                  marginTop: 20,
                  marginRight: 40
                }}
                onClick={onSubmitButtonHandler}
                modal="close"
              >
                {onSubmitButtonName}
              </Button>
            )}
            {secondaryButtonHandler && (
              <Button
                node="button"
                waves="light"
                style={{
                  marginTop: 20,
                  marginRight: 40
                }}
                onClick={secondaryButtonHandler}
                modal="close"
              >
                {secondaryButtonName}
              </Button>
            )}
            {!hideCancelButton && (
              <Button
                node="button"
                waves="light"
                modal="close"
                style={{
                  marginTop: 20,
                }}
                onClick={handleClose}
                >
                {translate('cancel')}
              </Button>
            )}
          </div>
      </div>
    </div>
  );
};

ModalComponent.propTypes = {
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  ModalContent: PropTypes.oneOfType([
    PropTypes.string, // For plain text
    PropTypes.func, // For passing a component
    PropTypes.node // For JSX or React elements
  ]).isRequired,
  onSubmitButtonHandler: PropTypes.func,
  onSubmitButtonName: PropTypes.string,
  secondaryButtonHandler: PropTypes.func,
  secondaryButtonName: PropTypes.string,
  type: PropTypes.oneOf(['WARNING']),
  hideCancelButton: PropTypes.bool
};

export default ModalComponent;
