import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useUserDataContext } from '../../contexts/userDataProvider';
import { Button } from "react-materialize";
import { amountOfMonthToReturn } from '../common/appSettings';
import SelectComponent from '../common/SelectComponent';
import { yearsSelectoptions } from './timeLineUtils';

const TimelineFilters = ({ setSelectedDate }) => {
  const { translate } = useUserDataContext();

  const monthsKeys = ["jan", "feb", "mar", "apr", "mayShort", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
  const currentYear = new Date().getFullYear();
  const currentMonthIndex = new Date().getMonth();

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonthIndex);

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value));
  };

  useEffect(() => {
    setSelectedDate(
      {
        dateFrom: new Date(selectedYear, selectedMonth, 1),
        dateTo: new Date(selectedYear, selectedMonth + amountOfMonthToReturn, 0),
      }
    )
  }, [selectedMonth, selectedYear, setSelectedDate]);

  const mobileSelectMonthsData = monthsKeys.map((month, index) => ({ name: translate(month), value: String(index) }));

  return (
    <div className="timelineFiltersWrap">
      <SelectComponent
        options={yearsSelectoptions}
        handleSelect={handleYearChange}
        value={yearsSelectoptions[yearsSelectoptions.length - 1].value}
      />
      <div className="monthsButtonsWrap">
        {Array.from({ length: 12 }, (_, i) => (
          <Button
            key={i}
            className={`monthButton ${selectedMonth === i ? 'monthButtonSelected' : ''}`}
            node="button"
            onClick={() => setSelectedMonth(i)}
            >
              {translate(monthsKeys[i])}
          </Button>
        ))}
      </div>
      <SelectComponent
        className="mobileMonthsSelectWrap"
        options={mobileSelectMonthsData}
        handleSelect={(value) => setSelectedMonth(parseInt(value.target.value))}
        value={mobileSelectMonthsData[selectedMonth].value}
      />
      <Button
        className="todayButton"
        node="button"
        onClick={() => setSelectedDate(
          {
            dateFrom: new Date(currentYear, currentMonthIndex, 1),
            dateTo: new Date(currentYear, currentMonthIndex + 1, 0),
          }
        )}
      >
        {translate('today')}
      </Button>
    </div>
  );
};

TimelineFilters.propTypes = {
  setSelectedDate: PropTypes.func.isRequired
};

export default TimelineFilters;
