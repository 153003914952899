import PropTypes from 'prop-types';
import { TextInput, Switch, Textarea } from "react-materialize";
import { getRequiredInputParams } from '../common/utils';
import { useUserDataContext } from '../../contexts/userDataProvider';

const OrderDetails = ({
  setData,
  newOrderData = {
    firstName: '',
    costPerNight: '',
    totalCost: 0,
    surname: '',
    email: '',
    phone: '',
    propertyId: '',
    propertyName: '',
    feeding: false,
    comments: '',
  }
}) => {
  const { translate, data } = useUserDataContext();

  const { isDisplayFeedingOption } = data ? data[0] : '';

  return (
    <div className={'order-details-wrap'}>
      <div>
        <TextInput
          label={`${translate('firstName')}*`}
          validate
          value={newOrderData.firstName}
          onChange = {(e) => setData({ firstName: e.target.value })}
          id="firstName"
          maxLength="30"
          {...getRequiredInputParams(translate('canNotBeEmpty'))}
        />
        <TextInput
          label={translate('surname')}
          value={newOrderData.surname}
          onChange = {(e) => setData({ surname: e.target.value })}
          id="surname"
          maxLength="30"
        />
        <TextInput
          label={translate('email')}
          email
          validate
          value={newOrderData.email}
          onChange = {(e) => setData({ email: e.target.value })}
          id="email"
          maxLength="30"
        />
      </div>
      <div>
        <TextInput
          label={`${translate('phone')}*`}
          validate
          value={newOrderData.phone}
          onChange = {(e) => setData({ phone: e.target.value })}
          id="phone"
          maxLength="16"
          {...getRequiredInputParams(translate('canNotBeEmpty'))}
        />
        {isDisplayFeedingOption ? (
          <div className="switch">
            <span className="switchLabel">{translate('feeding')}:</span>
            <Switch
              label={translate('feeding')}
              id="feeding-new-order"
              offLabel={translate('no')}
              onLabel={translate('yes')}
              checked={newOrderData.feeding}
              onChange = {(e) => setData({ feeding: e.target.checked })}
            />
          </div>
        ) : null}
        <Textarea
          label={translate('comments')}
          value={newOrderData.description}
          onChange = {(e) => setData({ description: e.target.value })}
          id="comments"
          maxLength="200"
          className="order-details-comment"
        />
      </div>
    </div>
  )
};

OrderDetails.propTypes = {
  newOrderData: PropTypes.shape({
    costPerNight: PropTypes.number,
    firstName: PropTypes.string,
    surname: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    propertyId: PropTypes.string,
    propertyName: PropTypes.string,
    feeding: PropTypes.bool,
    totalCost: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    comments: PropTypes.string
  }),
  setData: PropTypes.func.isRequired
};

export default OrderDetails;
