import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from "react-router";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './scss/style.scss';
import { PropertyProvider } from './contexts/propertyProvider';
import { UserDataProvider } from './contexts/userDataProvider';
import { CoworkerRequestProvider } from './contexts/coworkerRequestProvider';
import { USER_STATUS_ACTIVE } from './components/common/utils';
import Login from './components/Login';
import CoworkerRequestModal from './components/settings/coworkers/CoworkerRequestModal';
import Search from './components/search/Search';
import Header from './components/Header';
import SidePanel from './components/SidePanel';
import TermsAndConditions from './components/terms/TermsAndConditions';
import EmailNotVerified from './components/EmailNotVerified';
import NotActivated from './components/NotActivated';
import NoPage from './components/NoPage';
import TimeLineView from './components/timeline/TimeLineView';

const AppWrapper = () => {
  const [isTermsAcceptedInDb, setIsTermsAcceptedInDb ] = useState(undefined);
  const [userStatus, setUserStatus ] = useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    if (userStatus !== undefined && userStatus !== USER_STATUS_ACTIVE) {
      navigate('/not-activated');
    }
  }, [userStatus, navigate]);

  return (
    <>
      <Header
        setIsTermsAcceptedInDb={setIsTermsAcceptedInDb}
        setUserStatus={setUserStatus}
      />
      {
        isTermsAcceptedInDb === true ? (
          <>
            <SidePanel />
            <CoworkerRequestModal />
            <TimeLineView />
            <Search />
          </>
        ) : (isTermsAcceptedInDb === false && <TermsAndConditions />)
      }
    </>
  )
};

const Layout = () => (
    <Outlet />
  );

function App() {

  return (
    <BrowserRouter>
      <PropertyProvider>
        <UserDataProvider>
          <CoworkerRequestProvider>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route path="/app" element={<AppWrapper />} />
                <Route path="/landing" element={<Login />} />
                <Route path="/email-not-verified" element={<EmailNotVerified />} />
                <Route path="/not-activated" element={<NotActivated />} />
                <Route path="*" element={<NoPage />} />
              </Route>
            </Routes>
          </CoworkerRequestProvider>
        </UserDataProvider>
      </PropertyProvider>
    </BrowserRouter>
  );
}

export default App;
