export const dateFormat = 'DD-MMM-YYYY';

export const appStartDate = new Date(1695582866616); // Sep 24 2023; - earliest date possible to select in order search filter

// ----- Localisation
export const defaultLanguage = 'en';


// ----- Search
// To add another status:
// - add constant here,
// - add new key-value to "searchDaysLimit" in Search.jsx
export const userActiveSearchDaysLimit = 31; // Basic status


// ----- TImeline
// key of TimeLine ranges, can be different color for different key. 
export const defaultRangeKey = 'selection';


// ----- TimeLine filters
// Amount of data in month to be returned on month button click 
export const amountOfMonthToReturn = 2;
