import enLocale from '../../locales/en';
import ukLocale from '../../locales/uk';

export const todayDate = new Date();
export const inOneWeekDate = new Date(new Date().setDate(todayDate.getDate() + 7));

export const addOneDayToDate = (date) => {
  // Clone the original date to avoid modifying it directly
  const updatedDate = new Date(date);
  updatedDate.setDate(updatedDate.getDate() + 1);

  return updatedDate;
};

// ----- Localisation
export const supportedLanguages = [
  {name: 'English', value: 'en'},
  {name: 'Українська', value: 'uk'}
];

export const translations = {
  en: enLocale,
  uk: ukLocale,
};

export const translateMomentDate = (dateFrom, dateTo, translate) => {
  const translatedDateFrom = `${dateFrom.format('DD')} ${translate(dateFrom.format('MMM').toLowerCase())} ${dateFrom.format('YYYY')}`;
  const translatedDateTo = `${dateTo.format('DD')} ${translate(dateTo.format('MMM').toLowerCase())} ${dateTo.format('YYYY')}`;

  return `${translatedDateFrom} - ${translatedDateTo}`;
};

// ----- Roles
export const roleDefault = 'manager';

export const getRolesOptions = (translate) => {
  const statusKeys = [roleDefault, 'viewOnly'];

  return statusKeys.map(key => ({ name: translate(key), value: key }));
};

// ----- Orders
export const initStatus = 'unpaid';

export const getOrderStatusesOptions = (translate) => {
  const statusKeys = [initStatus, 'paid', 'partiallyPaid'];

  return statusKeys.map(key => ({ name: translate(key), value: key }));
};

// ----- User management
export const USER_STATUS_ACTIVE = 'active';

// ----- Coworker
export const coworkerRequestStatus = {
  pending: 'PENDING',
  approved: 'APPROVED',
  rejected: 'REJECTED'
};

// ----- Links
const prodAppLink = 'https://domevent.com';
const logoutPage = '/logout';
const pageUrl = window.location.pathname; // '/landing'
const landingPage = '/landing';
const emailNotVerifiedPage = '/email-not-verified';

export const loginLink = window.location.hostname === 'localhost' ? 'http://localhost:8080/login' : '/login';
export const logOutUrl = window.location.hostname === 'localhost' ? `http://localhost:8080${logoutPage}` : prodAppLink+logoutPage;

export const getRequiredInputParams = (errorMessage) => ({
  error: errorMessage,
  required: true,
  validate: true
});

const logOut = () => window.location.replace(logOutUrl); // Not allow back navigation
// const logOut = () => window.location.href = logOutUrl; // Allow back navigation

// All requests should use "handleError" in catch. Than show modal with mesage by "setError".
export const handleError = (status, statusText, message, setError) => {
  // Error handler with logout
  if (status === 401) {
    if (pageUrl !== landingPage && pageUrl !== emailNotVerifiedPage) { // Auto logout can't be on the static pages
      logOut();
    }
  } else {
    if (status === 500) {
      logOut();
    } else {
      setError({
        status: status,
        statusText: statusText,
        message: message
      });
    }
  }
};



// ----- Helpers

// Get all dates in reserved ranges as array of dates
export const getReservedDatesInOneProperty = (reservedRanges, selectedPropertyId, isEndDateSelected, isOrderEdit, currentlyEditedOrderData) => {
  if (!reservedRanges || !Array.isArray(reservedRanges) || !reservedRanges.length) {
    return [];
  }

  const allDates = [];

  reservedRanges.forEach(({ dateFrom, dateTo, propertyId }) => {
    if (propertyId === selectedPropertyId) {
      const fromDate = new Date(dateFrom);
      const toDate = new Date(dateTo);

      // If edit order, remove selected range, to be able to select dates in this range
      if (isOrderEdit && currentlyEditedOrderData) {
        const rangeStartDate = new Date(currentlyEditedOrderData.dateFrom);
        const rangeEndDate = new Date(currentlyEditedOrderData.dateTo);

        if (fromDate >= rangeStartDate && toDate <= rangeEndDate) {
          return; // Skip this range
        }
      }

      // Remove the last date from all ranges
      toDate.setDate(toDate.getDate() - 1);

      // If isEndDateSelected is false, remove the first date to be able select this date as dateTo
      if (isEndDateSelected) {
        fromDate.setDate(fromDate.getDate() + 1);
      }

      // If isOrderEdit - remove this range

      // build array with all dates of all ranges  
      while (fromDate <= toDate) {
        allDates.push(new Date(fromDate));
        fromDate.setDate(fromDate.getDate() + 1);
      }
    }
  });

  return allDates;
};

export const isSelectedDatesAvailable = (startDate, endDate, allReservedOrders, isOrderEdit, currentlyEditedOrderData) => {
  const resetTime = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());

  const start = resetTime(startDate);
  const end = resetTime(endDate);
  const selectedPropertyId = currentlyEditedOrderData.propertyId

  let reservedOrdersForProperty = allReservedOrders.filter(obj => obj.propertyId === selectedPropertyId);

  // Remove the range from reservedOrders if isOrderEdit is true, to make new selection in selected range valid
  if (isOrderEdit && currentlyEditedOrderData) {
    reservedOrdersForProperty = reservedOrdersForProperty.filter(order => (
      currentlyEditedOrderData.dateFrom !== order.dateFrom && currentlyEditedOrderData.dateTo !== order.dateTo
    ));
  }

  const startDateIsInRange = reservedOrdersForProperty.some(order => {
    const dateFrom = resetTime(new Date(order.dateFrom));
    const dateTo = resetTime(new Date(order.dateTo));

    return (start >= dateFrom && start <= dateTo);
  });

  const endDateIsInRange = reservedOrdersForProperty.some(order => {
    const dateFrom = resetTime(new Date(order.dateFrom));
    const dateTo = resetTime(new Date(order.dateTo));

    return (end >= dateFrom && end <= dateTo);
  });

  // Check if startDate and endDate overlap with any reserved order range
  const isOverlappingReservedOrderRange = reservedOrdersForProperty.some(order => {
    const dateFrom = resetTime(new Date(order.dateFrom));
    const dateTo = resetTime(new Date(order.dateTo));

    return (
      // startDate is in a range
      (start >= dateFrom && start <= dateTo) ||
      // endDate is in a range
      (end >= dateFrom && end <= dateTo) ||
      // dates between start and end overlaps reserved range
      (start <= dateFrom && end >= dateTo)
    );
  });
  
  // Check if startDate is the same as dateTo of any object and endDate is not in a range of any object dateFrom and dateTo
  const startDateSameAsDateTo = reservedOrdersForProperty.some(order => {
    const dateTo = resetTime(new Date(order.dateTo));

    return start.getTime() === dateTo.getTime();
  });

  // Check if endDate is the same as dateFrom of any object and startDate is not in a range of any object dateFrom and dateTo
  const endDateSameAsDateFrom = reservedOrdersForProperty.some(order => {
    const dateFrom = resetTime(new Date(order.dateFrom));
    return end.getTime() === dateFrom.getTime();
  });

  if (!isOverlappingReservedOrderRange) { // No overlap
    return true;
  }

  if (startDateSameAsDateTo && endDateSameAsDateFrom) {
    return true;
  }

  if (startDateSameAsDateTo && !endDateIsInRange) {
    return true;
  }

  if (endDateSameAsDateFrom && !startDateIsInRange) {
    return true;
  }

  return false; // Is Reserved
};

export const getObjectByValue = (arrayOfObjects, key, value) => {
  if (arrayOfObjects?.length) {
    return arrayOfObjects.find(obj => obj[key] === value);
  }

  return;
};

// Not used functions

// export const addOneDay = (dateInput) => {
//   const date = new Date(dateInput);
//   // Add 1 day to the date
//   date.setDate(date.getDate() + 1);
//   // Convert back to ISO 8601 string format
//   const result = date.toISOString();

//   return result;
// };

// export const getMonthAndYear = (date) => {
//   const monthName = date ? date.toLocaleString('default', { month: 'long' }) : todayDate.toLocaleString('default', { month: 'long' })
//   const year = date ? todayDate.getFullYear(date) : todayDate.getFullYear();

//   return monthName + "_" + year; // May_2024
// };

// export const getLastDayOfMonth = (firstDayOfMonth) => {
//   const lastDayOfMonth = new Date(firstDayOfMonth);

//   lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1);
//   lastDayOfMonth.setDate(0);

//   return lastDayOfMonth;
// }

// export const getFirstAndLastDatesInCurrentMonth = () => {
//   const firstDateOfMonth = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
//   const lastDateOfMonth = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0);

//   return {
//     dateFrom: firstDateOfMonth,
//     dateTo: lastDateOfMonth
//   }
// };

// export const getFirstAndLastDatesOfClickedMonth = (clickedDate) => {
//   const currentDate = new Date(clickedDate);
//   const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
//   const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

//   return {
//     dateFrom: firstDayOfMonth,
//     dateTo: lastDayOfMonth
//   }
// };

// Fuction to create not editable ranges with key 'reserved' to be used as reserved dates in <DateRange>
// so the disabledDates is not needed then. Dates can be combined with different key - [dataRange].concat(disabledDates);.
// export const getReservedRangesInOneProperty = (reservedOrders, selectedPropertyId) => {
//   if (!reservedOrders || !Array.isArray(reservedOrders)) {
//     return [];
//   }

//   let allDates = [];

//   reservedOrders.forEach(({ dateFrom, dateTo, propertyId }) => {
//     if (propertyId === selectedPropertyId) {
//       allDates.push({
//         startDate: dateFrom,
//         endDate: dateTo,
//         key: 'reserved',
//         color: 'rgba(0, 0, 0, 0.18)',
//         disabled: true
//       });
//     }
//   });

//   return allDates;
// };

// Get dates after today date
// export const getReservedRanges = (data, newOrderData) => {
//   if (!data || !Array.isArray(data)) {
//     return [];
//   }

//   let allDates = [];

//   data.forEach(({ dateFrom, dateTo, propertyId, status }) => {
//     if (propertyId === newOrderData.propertyId && status !== statusAvailable ) {
//       let currentDate = new Date(dateFrom);

//       while (currentDate <= new Date(dateTo)) {
//         allDates.push(new Date(currentDate));
//         currentDate.setDate(currentDate.getDate() + 1);
//       }
//     }
//   });

//   return allDates;
// };
