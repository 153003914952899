import PropTypes from 'prop-types';
import { Select } from "react-materialize";

const SelectComponent = ({ options, handleSelect, label, icon, value = '', className }) => (
    <Select
      icon={icon}
      label={label}
      multiple={false}
      onChange={handleSelect}
      options={{
        classes: `select ${className}`,
        dropdownOptions: {
          alignment: 'left',
          autoTrigger: true,
          closeOnClick: true,
          constrainWidth: true,
          coverTrigger: true,
          hover: false,
          inDuration: 150,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          outDuration: 250
        }
      }}
      value={value}
    >
      {
        options.map(({ name, value }) => (
            <option key={name} value={value}>{name}</option>
          ))
      }
    </Select>
  )

SelectComponent.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
  })).isRequired,
  handleSelect: PropTypes.func.isRequired,
  label: PropTypes.string,
  icon: PropTypes.node,
  value: PropTypes.string,
  className: PropTypes.string
};

export default SelectComponent;
