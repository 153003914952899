import { useState } from "react";
import PropTypes from 'prop-types';
import { MdDeleteForever } from "react-icons/md";
import { CiEdit } from 'react-icons/ci';
import { useUserDataContext } from '../../../contexts/userDataProvider';
import settingsAPI from '../../../services/settingsAPI';
import ordersAPI from '../../../services/ordersAPI';
import { fetchData } from '../../common/commonRequests';
import Loader from '../../common/Loader';
import ErrorAlert from '../../common/ErrorAlert';
import ModalComponent from '../../common/ModalComponent';

const PropertyItem = ({
    title,
    costPerNight,
    description = '',
    id,
    reloadPropertiesList,
    setIsEdit
  }) => {
  const { translate } = useUserDataContext();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [isControls, setIsControls] = useState(false);
  const [isRemoveDataModal, setIsRemoveDataModal] = useState(false);

  const removeGroupCallback = () => {
    setIsRemoveDataModal(false);
    reloadPropertiesList();
  };

  // Search all reservation without date range is expensive foor BE, so we don't check if records exists for the group
  const removeRecordsAndGroup = async () => {
      await fetchData(setIsLoading, setError, ordersAPI.removeOrdersByGroup, id);
      await fetchData(setIsLoading, setError, settingsAPI.removeProperty, id, removeGroupCallback);
  };

  const handleRemove = () => {
    setIsRemoveDataModal(true);
  };

  return (
    <>
      <Loader 
        setIsLoading={isLoading}
      />
      <ErrorAlert
        error={error}
      />
      <ModalComponent 
        ModalContent={translate('allRelatedReservationsWillBeRemoved')}
        onSubmitButtonName={translate('removeAccommodation')}
        onSubmitButtonHandler={removeRecordsAndGroup}
        show={isRemoveDataModal}
        handleClose={() => setIsRemoveDataModal(false)}
        type="WARNING"
      />
      <div
        className="property-item-wrap"
        onMouseEnter={() => setIsControls(true)}
        onMouseLeave={() => setIsControls(false)}
      >
        <div className="property-item-content">
          <div className="property-item-content-title">
            <p>{translate('title')}:</p>
            <p>{translate('costNight')}:</p>
            <p>{translate('description')}:</p>
          </div>
          <div className="property-item-content-value">
            <p>{ title }</p>
            <p>{ costPerNight }</p>
            <p className="property-item-description">{ description }</p>
          </div>
        </div>
        <div className="property-item-controls">
          {isControls && (
            <>
              <CiEdit
                className="controls-icons"
                size={'1.7em'}
                onClick={() => setIsEdit(true)}
                title={translate('edit')}
              />
              <MdDeleteForever
                className="controls-icons"
                size={'1.5em'}
                onClick={handleRemove}
                title={translate('remove')}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

PropertyItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  costPerNight: PropTypes.number.isRequired,
  reloadPropertiesList: PropTypes.func.isRequired,
  description: PropTypes.string
};

export default PropertyItem;
