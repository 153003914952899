import PropTypes from 'prop-types';
import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";
import moment from 'moment';
import { useUserDataContext } from '../../contexts/userDataProvider';
import { dateFormat, appStartDate } from './appSettings';
import { DatePicker } from "react-materialize";

const getOptions = (minDateToSelect, translate) => (
  {
    autoClose: false,
    container: null,
    defaultDate: null,
    disableDayFn: null,
    disableWeekends: false,
    events: [],
    firstDay: 1,
    format: 'dd mmm, yyyy',
    i18n: {
      cancel: '',
      clear: translate('clear'),
      done: '',
      months: [
        translate('january'),
        translate('february'),
        translate('march'),
        translate('april'),
        translate('may'),
        translate('june'),
        translate('july'),
        translate('august'),
        translate('september'),
        translate('october'),
        translate('november'),
        translate('december'),
      ],
      monthsShort: [
        translate('jan'),
        translate('feb'),
        translate('mar'),
        translate('apr'),
        translate('may'),
        translate('jun'),
        translate('jul'),
        translate('aug'),
        translate('sep'),
        translate('oct'),
        translate('nov'),
        translate('dec'),
      ],
      nextMonth: '›',
      previousMonth: '‹',
      weekdays: [
        translate('sunday'),
        translate('monday'),
        translate('tuesday'),
        translate('wednesday'),
        translate('thursday'),
        translate('friday'),
        translate('saturday'),
      ],
      weekdaysAbbrev: [
        translate('s'),
        translate('m'),
        translate('t'),
        translate('w'),
        translate('t'),
        translate('f'),
        translate('s'),
      ],
      weekdaysShort: [
        translate('sun'),
        translate('mon'),
        translate('tue'),
        translate('wed'),
        translate('thu'),
        translate('fri'),
        translate('sat'),
      ]
    },
    isRTL: false,
    maxDate: null,
    minDate: minDateToSelect || null,
    onClose: null,
    onDraw: null,
    onOpen: null,
    onSelect: null,
    parse: null,
    setDefaultDate: true,
    showClearBtn: false,
    showDaysInNextAndPreviousMonths: false,
    showMonthAfterYear: false,
    yearRange: 10
  }
);

// DatePicker returns not ISO 8601, but axios converts to ISO 8601 on JSON.stringify and lost 1 day.
// By default, JSON.stringify converts Date objects to ISO 8601 format in UTC.
const DatePickerComponent = ({ handleChange, selectedDate }) => {
  const { translate } = useUserDataContext();

  // Warning: You provided a `value` prop to a form field without an `onChange` handler.
  return (
      <div className="date-picker-wrap">
          <DatePicker
            label={translate('dateFrom')}
            value={moment(selectedDate.dateFrom).format(dateFormat)}
            id="dateFrom"
            onChange={(newDate) => {
              handleChange({
                target: {
                  id: "dateFrom",
                  value: newDate
                }
              })
            }} 
            options={getOptions(appStartDate, translate)}
          />
          <DatePicker
            label={translate('dateTo')}
            value={moment(selectedDate.dateTo).format(dateFormat)}
            id="dateTo"
            onChange={(newDate) => {
              handleChange({
                target: {
                  id: "dateTo",
                  value: newDate
                }
              })
            }} 
            options={getOptions(appStartDate, translate)}
          />
      </div>
  );
}

DatePickerComponent.propTypes = {
  handleChange: PropTypes.func.isRequired,
  selectedDate: PropTypes.shape({
    dateFrom: PropTypes.instanceOf(Date).isRequired,
    dateTo: PropTypes.instanceOf(Date).isRequired
  }).isRequired,
};

export default DatePickerComponent;