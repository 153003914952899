import { useState, useEffect, useCallback } from "react";
import { handleError } from './utils';

export const useFetch = (request) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const fetchData = useCallback( async () => {
    setIsLoading(true);
    setError({});

    try {
      const fetchedData = await request();

      setData(fetchedData);
    } catch (err) {
      handleError(
        err.response?.status,
        err.response?.statusText,
        err.response?.data.message,
        setError
      );
    } finally {
      setIsLoading(false);
    }
  }, [request]);

  useEffect(() => { // TODO: check wjy this is needed here. Can useGetOrders be used?
    fetchData();
  }, [fetchData]);

  const reloadData = () => {
    fetchData();
  };

  // const reloadData = async () => {
  //   await fetchData();
  // };

  return { data, isLoading, error, reloadData };
};

export const useGetOrders = (request, params) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const fetchOrderData = useCallback(async () => {
    setIsLoading(true);
    setError({});

    try {
      const fetchedData = await request(params);

      setData(fetchedData);
    } catch (err) {
      handleError(
        err.response?.status,
        err.response?.statusText,
        err.response?.data.message,
        setError
      );
    } finally {
      setIsLoading(false);
    }
  }, [request, params, setIsLoading, setError, setData]);

  const reloadData = useCallback(() => {
    fetchOrderData();
  }, [fetchOrderData]);

  return { data, isLoading, error, reloadData };
};

// hooks loads data on component load, so "setIsEditDataRequest" controll request send
export const useEdit = (request, data, callback) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [isEditDataRequest, setIsEditDataRequest] = useState(false);

  const editData = useCallback(async () => {
    setIsLoading(true);
    setError({});

    try {
      await request(data);
      callback && callback();
    } catch (err) {
      handleError(
        err.response?.status,
        err.response?.statusText,
        err.response?.data.message,
        setError
      );
    }
    finally {
      setIsLoading(false);
    }
  }, [request, data, callback]);

  useEffect(() => {
    if (isEditDataRequest) {
      editData();
      setIsEditDataRequest(false);
    }
  }, [editData, isEditDataRequest, request, callback]);

  const sendEditDataRequest = () => { // To controll when request should be trigered
    setIsEditDataRequest(true);
  };

  return { isLoading, error, sendEditDataRequest };
};
