import { useState, useEffect } from "react";
import { useUserDataContext } from '../../contexts/userDataProvider';
import { Button } from "react-materialize";
import ordersAPI from '../../services/ordersAPI';
import { usePropertyContext } from '../../contexts/propertyProvider';
import { useGetOrders } from '../common/fetchData.hooks';
import { todayDate, inOneWeekDate, getOrderStatusesOptions, initStatus, USER_STATUS_ACTIVE } from '../common/utils';
import { userActiveSearchDaysLimit } from '../common/appSettings';
import DatePickerComponent from '../common/DatePickerComponent';
import SelectComponent from '../common/SelectComponent';
import Loader from '../common/Loader';
import ErrorAlert from '../common/ErrorAlert';
import CardOrderComponent from './CardOrderComponent';

const getSearchDaysLimit = (userStatus) => {
  if (!userStatus) {
    return
  }

  const searchDaysLimit = {
    [USER_STATUS_ACTIVE]: userActiveSearchDaysLimit
  };

  return searchDaysLimit[userStatus];
};

const Search = () => {
  const { translate, data: userData } = useUserDataContext();

  const [selectedDate, setSelectedDate] = useState({
    dateFrom: todayDate,
    dateTo: inOneWeekDate,
  });
  const [orderType, setOrderType] = useState(initStatus);
  const [isdaysLimitExceed, setIsdaysLimitExceed] = useState(false);

  const {
    data: propertyData,
    isLoading: isPropertyLoading,
    error: errorProperty,
  } = usePropertyContext([]);

  const { data: orders, isLoading: isOrderLoading, error: errorOrder, reloadData } = useGetOrders(
    ordersAPI.getOrders,
    {selectedDate, orderType}
  );
 
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const userStatus = userData[0].userStatus;
  const searchDaysLimit = getSearchDaysLimit(userStatus);

  const handleSelectType = (e) => {
    setOrderType(e.target.value)
  };

  const handleDatePicker = (e) => {
    const key = e.target.id;
    const val = e.target.value;
    const newState = {...selectedDate};
    newState[key] = val;

    setSelectedDate(newState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    reloadData();
  };

  const reloadOrders = () => {
    reloadData();
  }
  
  // Calculate the difference in days between the start and end dates
  const getDaysDifference = (startDate, endDate) => {
    const oneDay = 24 * 60 * 60 * 1000; // Hours*Minutes*Seconds*Milliseconds
    return Math.round((endDate - startDate) / oneDay);
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // "reloadData" can't be added, because of infinity reloading


  useEffect(() => {
    setIsdaysLimitExceed(getDaysDifference(selectedDate.dateFrom, selectedDate.dateTo) > searchDaysLimit);
  }, [selectedDate.dateFrom, selectedDate.dateTo, userStatus, searchDaysLimit]);

  return (
    <>
      <Loader 
        setIsLoading={isLoading || isOrderLoading || isPropertyLoading}
      />
      <ErrorAlert
        error={error || errorOrder || errorProperty}
      />
      {propertyData?.length ? (
        <>
          <form onSubmit={handleSubmit} className="filters-wrap">
            <DatePickerComponent
              handleChange={handleDatePicker}
              selectedDate={selectedDate}
            />
            <SelectComponent
              options={getOrderStatusesOptions(translate)}
              handleSelect={handleSelectType}
              label={translate('selectStatus')}
            />
            <Button
              className="submit-button"
              node="button"
              type="submit"
              disabled={isdaysLimitExceed}
            >
              {translate('search')}
            </Button>
          </form>
          {isdaysLimitExceed && <p className="validation-message">{translate('selectedNumberOfDaysMustNotExceed')} {searchDaysLimit}</p>}
          <div className="card-wrap">
            {orders.map((order) => (
              <CardOrderComponent
                key={order.id}
                orderData={order}
                setIsLoading={setIsLoading}
                setError={setError}
                reloadOrders={reloadOrders}
              />
            ))}
            {orders.length === 0 && <div className="nothing-found">{translate('nothingFound')}</div>}
          </div>
        </>
      ) : ''}
    </>
  );
};

export default Search;
