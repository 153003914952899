import ordersAPI from '../../services/ordersAPI';
import { handleError, initStatus } from './utils';
import { defaultRangeKey } from './appSettings';

export const initOrderData = {
  propertyId: '',
  propertyName: '',
  firstName: '',
  surname: '',
  email: '',
  phone: '',
  feeding: false,
  description: '',
  totalCost: '',
  status: initStatus
};

// TODO: rewrite to common function for all POST request
export const addNewOrderRequest = async (
    setIsLoading,
    setError,
    newOrderData,
    startDate,
    endDate,
    setNewOrderData,
    callback
  ) => {

  try {
    setIsLoading(true);
    setError({});
    await ordersAPI.saveOrder({
      ...newOrderData,
      dateFrom: startDate,
      dateTo: endDate,
      costPerNight: newOrderData.costPerNight
    });
    setNewOrderData(initOrderData);

    if (callback) {
      callback();
    } // Update calendar
  } catch (error) {
    handleError(
      error.response?.status,
      error.response?.statusText,
      error.response?.data.message,
      setError
    );
  } finally {
    setIsLoading(false);
  }
};

export const saveEditedItem = async ( // TODO: use common request from utils.js
    setIsLoading,
    setError,
    orderId,
    editOrderData,
    orderReloadData, // can be also callback in some components
    callback
  ) => {
  try {
    setIsLoading(true);
    setError({});

    await ordersAPI.editOrder(
      orderId,
      editOrderData
    );

    orderReloadData();

    if (callback) {
      callback();
    }
  } catch (error) {
    handleError(
      error.response?.status,
      error.response?.statusText,
      error.response?.data.message,
      setError
    );
  } finally {
    setIsLoading(false);
  }
};

export const handleEditOrderDataLoad = async (setIsLoading, setError, setDataRange, setOrderData, orderId) => {
  try {
    setIsLoading(true);
    setError({});
    const orderData = await ordersAPI.getOneOrder(orderId);

    // If order data is found, update state
    if (orderData.length > 0) {
      const order = orderData[0];

      setDataRange(
        {
          startDate: new Date(order.dateFrom),
          endDate: new Date(order.dateTo),
          key: defaultRangeKey
        }
      );
      setOrderData(order);

    } else {
      // Handle case when order data is not found
      handleError(
        500,
        `Order data not found for ID - ${orderId}`,
        'Try reload the page or contact administrator',
        setError
      );
    }
  } catch (error) {
    handleError(
      error.response?.status,
      error.response?.statusText,
      error.response?.data.message,
      setError
    );
  } finally {
    setIsLoading(false);
  }
};

export const removeItem = async (setIsLoading, setError, orderId, callback) => {
  try {
    setIsLoading(true);
    setError({});
    await ordersAPI.removeOrder(orderId);
    
    if (callback) {
      callback();
    }
  } catch (error) {
    handleError(
      error.response?.status,
      error.response?.statusText,
      error.response?.data.message,
      setError
    );
  } finally {
    setIsLoading(false);
  }
};
