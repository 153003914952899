import { useState } from "react";
import { Transition } from 'react-transition-group';
import { useRef } from 'react';
import { GrMenu } from 'react-icons/gr';
import { AiOutlineClose } from 'react-icons/ai';
import Loader from './common/Loader';
import ErrorAlert from './common/ErrorAlert';
import { useCoworkerRequestContext } from '../contexts/coworkerRequestProvider';
import AllProperties from './settings/properties/AllProperties';
import UserSettings from './settings/UserSettings';
import RemoveAccount from "./settings/RemoveAccount";

const duration = 200;

const defaultStyle = {
  borderLeft: '1px solid #737373',
  borderBottom: '1px solid #737373',
  zIndex: 999,
  backgroundColor: '#fff',
  transition: `width ${duration}ms`,
  width: 0,
  height: 'auto',
  position: 'absolute',
  top: 0,
  right: 0
}

const transitionStyles = {
  entering: { width: 0 },
  entered: { width: '80vw' },
  exiting: { width: '80vw' },
  exited: { width: 0 }
};

const SidePanel = () => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [isCommonLoading, setIsCommonLoading] = useState(false);
  const [commonError, setCommonError] = useState({});

  const {
    isLoading: isLoadingCoworkerData,
    error: errorCoworkerData,
    isApprovedCoworker,
    workSpaceEmail,
    workSpaceId
  } = useCoworkerRequestContext();
  
  const nodeRef = useRef(null);

  return (
    <div className="side-nav-wrap">
      <Loader 
        setIsLoading={isCommonLoading || isLoadingCoworkerData}
      />
      <ErrorAlert
        error={commonError || errorCoworkerData}
      />
      <GrMenu
        onClick={() => setIsSideNavOpen(true)}
        className="open-nav-button"
        title="Settings"
      />
      <Transition 
        // addEndListener={() => console.log("Finish!")}
        // onEnter={() => console.log("onEnter!")}
        appear={false} nodeRef={nodeRef} in={isSideNavOpen} timeout={0}>
        {state => (
          <div ref={nodeRef} style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}>
            <div className={`side-nav-content ${isSideNavOpen ? '' : 'hidden'}`}>
              <AiOutlineClose
                className="sidenav-close"
                size={'2em'}
                onClick={() => setIsSideNavOpen(false)}
              />

              {!isApprovedCoworker && (
                <div className="sidenav-row-odd">
                  <AllProperties />
                </div>
              )}

              <div className="sidenav-row-even">
                <UserSettings
                  setIsSidePannelLoading={setIsCommonLoading}
                  setSidePannelError={setCommonError}
                  isApprovedCoworker={isApprovedCoworker}
                  workSpaceEmail={workSpaceEmail}
                  workSpaceId={workSpaceId}
                />
              </div>
              <div className="sidenav-row-odd">
                <RemoveAccount
                  setIsLoading={setIsCommonLoading}
                  setLocalError={setCommonError}
                  workSpaceEmail={workSpaceEmail}
                />
              </div>
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};

export default SidePanel;
