import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { TextInput } from "react-materialize";
import { usePropertyContext } from '../../contexts/propertyProvider';
import { useUserDataContext } from '../../contexts/userDataProvider';
import { getOrderStatusesOptions } from '../common/utils';
import { getTotalCost } from '../common/addNewOrdersUtils';
import SelectComponent from '../common/SelectComponent';
import { getObjectById } from './timeLineUtils';

const OrderSummary = ({
  setData,
  newOrderData = {
    firstName: '',
    costPerNight: '',
    totalCost: 0,
    surname: '',
    email: '',
    phone: '',
    propertyId: '',
    propertyName: '',
    feeding: false,
    comments: '',
  },
  dataRange,
  isOrderEdit
}) => {
  const { translate } = useUserDataContext();
  const { data } = usePropertyContext();

  const [costSum, setCostSum] = useState();
  const [isFinalCostMark, setIsFinalCostMark] = useState(false);

  const selectedPropertyData = getObjectById(data, newOrderData.propertyId);

  useEffect(() => {
    const totalCost = getTotalCost(newOrderData.costPerNight, dataRange);

    // Add final cost style
    if (Number(newOrderData.totalCost) !== 0 && Number(newOrderData.totalCost) !== totalCost && totalCost !== 0 && isOrderEdit) {
      setIsFinalCostMark(true);
    } else {
      setIsFinalCostMark(false);
    }

    // Set all nights Cost
    setCostSum(totalCost);
  }, [dataRange, newOrderData.costPerNight, isOrderEdit, newOrderData.totalCost]);

  // On Edit, the editable "totalCost" is not recalculated when days amount changed, because we don't know the reason of change 
  return (
    <div className={'order-summary-wrap'}>
      <div className={'cost-details'}>
        <p>{selectedPropertyData?.title}</p>
        <div><span className="allNightsCost">{translate('allNightsCost')}:</span> {costSum || "--"}</div>
        <div className={`totalCost ${isFinalCostMark ? 'markedInput' : ''}`}>
          <TextInput
            label={translate('totalCostEditable')}
            value={newOrderData.totalCost?.toString() || costSum?.toString() || ''}
            onChange = {(e) => setData({ totalCost: e.target.value })}
            onClick={() => setIsFinalCostMark(false)}
            id="totalCost"
            maxLength="8"
          />
          <p className="finalCostWarning">{isFinalCostMark ? translate('pleaseCheckCorrectness') : ''}</p>
        </div>
        <div className={'status'}>
          <SelectComponent
            options={getOrderStatusesOptions(translate)}
            handleSelect = {(e) => setData({ status: e.target.value })}
            label={translate('changeStatus')}
            value={newOrderData.status}
          />
        </div>
      </div>
      <div>
        {selectedPropertyData?.description && (
            <div className="description">
              <p>{ selectedPropertyData.description }</p>
            </div>
         )}
      </div>
    </div>
  )
};

OrderSummary.propTypes = {
  newOrderData: PropTypes.shape({
    costPerNight: PropTypes.number,
    firstName: PropTypes.string,
    surname: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    propertyId: PropTypes.string,
    propertyName: PropTypes.string,
    feeding: PropTypes.bool,
    totalCost: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    comments: PropTypes.string
  }),
  setData: PropTypes.func.isRequired,
  isOrderEdit: PropTypes.bool.isRequired
};

export default OrderSummary;
