import PropTypes from 'prop-types';

const KeyFeatures = ({ translate }) => (
    <div className="key-features">
      <ul className="key-features-list">
        <li><strong>- {translate('freeAccount')}:</strong><p>{translate('noCreditCartRequired')}</p></li>
        <li><strong>- {translate('interactiveTimeline')}:</strong><p>{translate('schedulingWithVisualRepresentation')}</p></li>
        <li><strong>- {translate('paymentStatuses')}:</strong><p>{translate('filterOrdersByPaymentStatus')}</p></li>
        <li><strong>- {translate('quickSearch')}:</strong><p>{translate('findReservationsByDateAndStatus')}</p></li>
        <li><strong>- {translate('employeeAccounts')}:</strong><p>{translate('secureLoginAndRoleAccess')}</p></li>
        <li><strong>- {translate('mobileResponsive')}:</strong><p>{translate('fullyResponsiveUiForBooking')}</p></li>
      </ul>
      <img className="preview-img" src={`${process.env.PUBLIC_URL}/images/timeline-example.jpg`} alt="preview" />
    </div>
  )

  KeyFeatures.propTypes = {
    translate: PropTypes.func.isRequired
  };
  
export default KeyFeatures;
