import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Card, Button } from "react-materialize";
import moment from 'moment';
import { useUserDataContext } from '../../contexts/userDataProvider';
import ordersAPI from '../../services/ordersAPI';
import {
  handleError,
  getOrderStatusesOptions,
  translateMomentDate
} from '../common/utils';
import { saveEditedItem } from '../common/orderRequests'
import { getTotalCost } from '../common/addNewOrdersUtils';
import ModalComponent from '../common/ModalComponent';
import CartContent from './CartContent';
import CartContentEdit from './CartContentEdit';
import SelectComponent from '../common/SelectComponent';

const CardOrderComponent = ({
    orderData,
    setIsLoading,
    setError,
    reloadOrders
  }) => {
    const { translate, data } = useUserDataContext();

    const { isDisplayFeedingOption } = data ? data[0] : '';

    const [isModal, setIsModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editOrderData, setEditOrderData] = useState({
      editFirstName: orderData.firstName,
      editSurname: orderData.surname,
      dateFrom: orderData.dateFrom,
      dateTo: orderData.dateTo,
      editEmail: orderData.email,
      editPhone: orderData.phone,
      editFeeding: orderData.feeding,
      editDescription: orderData.description,
      id: orderData.id,
      editCostPerNight: orderData.costPerNight,
      totalCost: orderData.totalCost
    });
    const [costSum, setCostSum] = useState();

    const onEditSuccess = () => {
      setIsEditMode(false)
      reloadOrders()
    };

    const removeItem = async () => {
      try {
        setIsLoading(true);
        setError({});
        await ordersAPI.removeOrder(orderData.id);
        reloadOrders();
      } catch (error) {
        handleError(
          error.response?.status,
          error.response?.statusText,
          error.response?.data.message,
          setError
        );
      } finally {
        setIsLoading(false);
      }
    };

    const parseEditOrderData = (editData) => ({
      firstName: editData.editFirstName,
      surname: editData.editSurname,
      dateFrom: editData.dateFrom,
      dateTo: editData.dateTo,
      email: editData.editEmail,
      phone: editData.editPhone,
      feeding: editData.editFeeding,
      description: editData.editDescription,
      totalCost: editData.totalCost
    });

    const EditModeButtons = ({ isEditMode }) => (
      isEditMode ? (
        <div className="edit-mode-buttons-wrap">
          <a href="#/" onClick={() => setIsEditMode(false)}>{translate('cancel')}</a>
          <a
            style={{
              marginLeft: '20px'
            }}
            href="#/" // TODO: replace by Button
            onClick={() => saveEditedItem(
                setIsLoading,
                setError,
                orderData.id,
                parseEditOrderData(editOrderData),
                onEditSuccess
              )
            }
          >
            {translate('save')}
          </a>
        </div>
      ) : null
    ); 

    const handleSelectType = (e) => {
      saveEditedItem(
        setIsLoading,
        setError,
        orderData.id,
        { status: e.target.value },
        onEditSuccess
      )
    };

    useEffect(() => {
      if (editOrderData.editCostPerNight && editOrderData.dateFrom && editOrderData.dateTo) {
        const totalCost = getTotalCost(
            editOrderData.editCostPerNight,
            {startDate: editOrderData.dateFrom, endDate: editOrderData.dateTo}
          );
  
        setCostSum(totalCost);
      }

    }, [editOrderData]);

    return (
      <Card
        actions={
          !isEditMode ? [
              <Button
                key={`1${orderData.id}`}
                node="button"
                waves="light"
                onClick={() => setIsModal(true)}
              >
                {translate('remove')}
              </Button>,
              <Button
                style={{
                  float: 'right'
                }}
                key={`2${orderData.id}`}
                node="button"
                waves="light"
                onClick={() => setIsEditMode(true)}
              >
                {translate('edit')}
              </Button>
            ] : [
              <EditModeButtons
                key={`3${orderData.id}`}
                isEditMode={isEditMode}
              />
            ]
        }
        className="card-component-wrap card-component blue-grey darken-1"
        textClassName="white-text"
      >
        <ModalComponent 
          ModalContent={translate('thisReservationWillBeRemoved')}
          onSubmitButtonHandler={removeItem}
          onSubmitButtonName={translate('yesRemove')}
          show={isModal}
          handleClose={() => setIsModal(false)}
          type="WARNING"
        />
        <div className="card-header">
          <div className="card-name">{orderData.propertyName}:</div>
          <div>
            {translateMomentDate(
              moment(orderData.dateFrom),
              moment(orderData.dateTo),
              translate
            )}
          </div>
        </div>
        <SelectComponent
          options={getOrderStatusesOptions(translate)}
          handleSelect={handleSelectType}
          label={translate('changeStatus')}
          value={orderData.status}
        />
        {isEditMode ? (
          <CartContentEdit
            editOrderData={editOrderData}
            setEditOrderData={setEditOrderData}
            costSum={costSum}
            isDisplayFeedingOption={isDisplayFeedingOption}
          />
        ): (
          <CartContent
            orderData={orderData}
            costSum={costSum}
            isDisplayFeedingOption={isDisplayFeedingOption}
          />
        )}
      </Card>
    )
  };

  CardOrderComponent.propTypes = {
    orderData: PropTypes.shape({
      firstName: PropTypes.string,
      surname: PropTypes.string,
      dateFrom: PropTypes.string,
      dateTo: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      feeding: PropTypes.bool,
      description: PropTypes.string,
      id: PropTypes.string,
      costPerNight: PropTypes.number,
      totalCost: PropTypes.number,
      createdAt: PropTypes.string,
      propertyId: PropTypes.string,
      propertyName: PropTypes.string,
      status: PropTypes.string
    }).isRequired,
    setIsLoading: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    reloadOrders: PropTypes.func.isRequired
  };

export default CardOrderComponent;
