import { createContext, useContext } from 'react';
import { useFetch } from '../components/common/fetchData.hooks';
import settingsAPI from '../services/settingsAPI';

const PropertyContext = createContext();

export const PropertyProvider = ({ children }) => {
  const { data, isLoading, error, reloadData } = useFetch(settingsAPI.getAllProperties);

  return (
    <PropertyContext.Provider value={{
        data,
        isLoading,
        error,
        reloadData
      }}
    >
      {children}
    </PropertyContext.Provider>
  );
};

export const usePropertyContext = () => useContext(PropertyContext);
